import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Our Ville in-house competition is today!   Please arrive by 8:30am,
first wod to start at 9:00.  This competition is old school style
CrossFit…be ready for the unknown and unknowable.  We’ll spin the
wheel to determine the workouts on the morning of the competition!  And
yes, we’ll be in our socially distanced lanes with our judges outside of
the lanes counting reps and dishing out no reps.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{` Here’s the basic outline:`}</em></strong></p>
    <p>{`WOD 1A:  21-15-9 of:`}</p>
    <p>{`?`}</p>
    <p>{`?`}</p>
    <p>{`WOD 1B:  In remaining time establish 1RM of:`}</p>
    <p>{`?`}</p>
    <p>{`WOD 2: 10:00 AMRAP of:`}</p>
    <p>{`?`}</p>
    <p>{`?`}</p>
    <p>{`?`}</p>
    <p>{`WOD 3: 18:00 AMRAP of:`}</p>
    <p>{`?`}</p>
    <p>{`?`}</p>
    <p>{`?`}</p>
    <p>{`?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      